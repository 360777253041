// ** React Imports
import React, { Suspense, lazy } from "react"
import ReactDOM from "react-dom/client"

// ** Redux Imports
import { Provider } from "react-redux"
import { store } from "./redux/storeConfig/store"

// ** Toast & ThemeColors Context
import { ToastContainer } from "react-toastify"
import { ThemeContext } from "./utility/context/ThemeColors"

// ** ACL
import ability from "./configs/acl/ability"

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner"

// ** Ripple Button
import "./@core/components/ripple-button"

// ** PrismJS
import "prismjs"
import "prismjs/themes/prism-tomorrow.css"
import "prismjs/components/prism-jsx.min"

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css"

// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss"

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css"
import "./@core/scss/core.scss"
import "./assets/scss/style.scss"

import "@uppy/core/dist/style.css"
import "@uppy/progress-bar/dist/style.css"

import "react-contexify/dist/ReactContexify.min.css"
import "@styles/react/libs/context-menu/context-menu.scss"

import 'sweetalert2/src/sweetalert2.scss'

import 'animate.css/animate.css'
import '@styles/base/plugins/extensions/ext-component-sweet-alerts.scss'

import '@styles/react/libs/tables/react-dataTable-component.scss'

// ** FireStore
import { FirebaseAppProvider, SuspenseWithPerf } from "reactfire"
import { firebaseConfigCargoV2 } from "./utility/environments/environments"

// ** Service Worker
import * as serviceWorker from "./serviceWorker"

import { QueryClient, QueryClientProvider } from "react-query"
import { AbilityContext } from "./utility/context/Can"
import { IntlProviderWrapper } from "./utility/context/Internationalization"

import { defineCustomElements } from "@ionic/pwa-elements/loader"

import { ReactQueryDevtools } from "react-query/devtools"

import { ApolloClient, InMemoryCache, ApolloProvider, split, HttpLink } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'

// ** Lazy load app
const LazyApp = lazy(() => import("./App"))

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      retryDelay: 5000
    },
    watchQuery: {
      nextFetchPolicy: 'cache-first'
    }
  }
})

// ** Apollo Client

const httpLink = new HttpLink({
  uri: 'https://server03.yehey.jp/graphql'
})

const wsLink = new GraphQLWsLink(createClient({
  url: 'wss://server03.yehey.jp/graphql--ws'
}))

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink
)

const apolloClient = new ApolloClient({
  uri: splitLink,
  cache: new InMemoryCache()
})

// ReactDOM.createRoot(document.getElementById('root')).render(
//   <FirebaseAppProvider firebaseConfig={firebaseConfigCargoV2}>
//     <SuspenseWithPerf fallback={null} traceId="storage-root">
//       <QueryClientProvider client={queryClient}>
//         <Provider store={store}>
//           <Suspense fallback={<Spinner />}>
//             <AbilityContext.Provider value={ability}>
//               <ThemeContext>
//                 <IntlProviderWrapper>
//                   {process.env.NODE_ENV === "development" && <ReactQueryDevtools initialIsOpen={false} />}
//                   <LazyApp />
//                   <ToastContainer newestOnTop />
//                 </IntlProviderWrapper>
//               </ThemeContext>
//             </AbilityContext.Provider>
//           </Suspense>
//         </Provider>
//       </QueryClientProvider>
//     </SuspenseWithPerf>
//   </FirebaseAppProvider>
// )

//React 18
const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <ApolloProvider client={apolloClient}>
    <FirebaseAppProvider firebaseConfig={firebaseConfigCargoV2}>
      <SuspenseWithPerf fallback={null} traceId="storage-root">
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <Suspense fallback={<Spinner />}>
              <AbilityContext.Provider value={ability}>
                <ThemeContext>
                  <IntlProviderWrapper>
                    {process.env.NODE_ENV === "development" && (
                      <ReactQueryDevtools initialIsOpen={false} />
                    )}
                    <LazyApp />
                    <ToastContainer newestOnTop />
                  </IntlProviderWrapper>
                </ThemeContext>
              </AbilityContext.Provider>
            </Suspense>
          </Provider>
        </QueryClientProvider>
      </SuspenseWithPerf>
    </FirebaseAppProvider>
  </ApolloProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
defineCustomElements(window)
