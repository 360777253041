import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Link, Redirect, useHistory } from 'react-router-dom'

export const MySwal = withReactContent(Swal)

export const useAlert = (data) => {
    return MySwal.fire(data)
}

export const alertSuccess = (text, title = "Success!") => {
    return MySwal.fire({
        title,
        text,
        icon: 'success',
        customClass: {
            confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
    })
}

export const alertInfo = (text, title = 'Info!') => {
    return MySwal.fire({
        title,
        text,
        icon: 'info',
        customClass: {
            confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
    })
}

export const alertWarning = (text, title = 'Warning!') => {
    return MySwal.fire({
        title,
        text,
        icon: 'warning',
        customClass: {
            confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
    })
}

export const alertError = (text, title = 'Error!') => {
    return MySwal.fire({
        title,
        text,
        icon: 'error',
        customClass: {
            confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
    })
}

export const alertErrorLogout = (text, title = 'Error!') => {
    const history = useHistory()
    console.log(history)
    return MySwal.fire({
        title,
        text,
        icon: 'error',
        customClass: {
            confirmButton: 'btn btn-primary'
        },
        confirmButtonText: (
            <span className='align-middle'>
                <span className='align-middle'>Great!</span>
            </span>
        ),
        buttonsStyling: false
    })
}