import { v5 as uuidv5 } from 'uuid'
import currency from "currency.js"
import momentz from 'moment-timezone'
import axios from 'axios'
import { AbilityBuilder, Ability } from '@casl/ability'
import { initialAbility } from '../configs/acl/initialAbility'
import { AbilityContext } from './context/Can'
import { useContext } from 'react'
import { useQuery } from "react-query"

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))
export const setUserData = (data) => localStorage.setItem('userData', JSON.stringify(data))


/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

// ** Ability Updater
export const updateAbility = (ability, user) => {
  const { can, rules } = new AbilityBuilder(Ability)

  if (user?.ability) {
    user.ability.forEach((i) => {
      can(i.action, i.subject)
    })
  }
  if (process.env.NODE_ENV === "development") console.log(rules)
  ability.update([...rules, ...initialAbility])
}

export const canViewItem = item => {
  const ability = useContext(AbilityContext)
  return ability.can(item.action, item.resource)
}

export const queryLimit = {
  $limit: 200
}

export const getCrumble = (path) => {
  if (path) {
    const p = path.split("/")
    const d = []
    p.forEach(i => {
      if (i) d.push(`${i.slice(0, 1).toUpperCase()}${i.slice(1)}`)
    })
    return d
  }
}

export const getKey = (length) => {
  const uuidKey = process.env.REACT_APP_QUERY_KEY
  //moment().format('LL') minus 1 hour for Singapore time 
  return `${uuidv5(`${momentz().tz("Asia/Tokyo").format("LL")}-${length}-yehey`, uuidKey)}`
}
export const queryFetcher3 = async (key) => {
  const query = key.queryKey[1]
  const url = `${"https://api.yehey.jp/query/aggregate"}?db=${query.db}&col=${query.col}&key=${getKey(JSON.stringify(query.agg).length)}`

  const res = await fetch(url, {
    method: "POST",
    body: JSON.stringify([...query.agg, queryLimit]),
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    },
    credentials: "same-origin"
  })
  return await res.json()
}


export const queryFetcher2 = async (key) => {
  const query = key.queryKey[1]
  const url = `${"https://api.yehey.jp/query/aggregate"}?db=${query.db}&col=${query.col}&key=${getKey(JSON.stringify(query.agg).length)}`

  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: JSON.stringify(query.agg),
    url
  }
  const { data } = await axios(options)
  return data

}

export const queryFetcher = async (key) => {
  const query = key.queryKey[1]
  let url
  if (process.env.NODE_ENV === "production") {
    url = `${process.env.REACT_APP_AGGREGATE_URL}?db=${query.db}&col=${query.col}`
  } else {
    url = `${"https://api.yehey.jp/query/aggregate"}?db=${query.db}&col=${query.col}&key=${getKey(JSON.stringify(query.agg).length)}`
  }
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: JSON.stringify(query.agg),
    url,
    withCredentials: true
  }

  const { data } = await axios(options)
  return data
}

export const bulkWriter = async (query) => {
  let url
  if (process.env.NODE_ENV === "production") {
    url = `${process.env.REACT_APP_BULKWRITE_URL}?db=${query.db}&col=${query.col}`
  } else {
    url = `${"https://api.yehey.jp/query/bulkWrite"}?db=${query.db}&col=${query.col}&key=${getKey(JSON.stringify(query.agg).length)}`
  }
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: JSON.stringify(query.agg),
    url,
    withCredentials: true
  }
  const { data } = await axios(options)
  return data
}


export const bulkWriter2 = async (query) => {
  const url = `${"https://api.yehey.jp/query/bulkWrite"}?db=${query.db}&col=${query.col}&key=${getKey(JSON.stringify(query.agg).length)}`

  const res = await fetch(url, {
    method: "POST",
    body: JSON.stringify(query.agg),
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    },
    credentials: "include"
  })
  return await res.json()

}

export const deletePostData = async (query) => {

  const url = `${query.url}?db=${query.db}&col=${query.col}&key=${getKey(JSON.stringify(query.data).length)}`

  const res = await fetch(url, {
    method: "POST",
    body: JSON.stringify(query.data),
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    }
  })
  const res_1 = await res.json()
  return res_1
}

export const updatePostData = async (query) => {

  const url = `${query.url}?db=${query.db}&col=${query.col}&key=${getKey(JSON.stringify(query.data).length)}`

  const res = await fetch(url, {
    method: "POST",
    body: JSON.stringify(query.data),
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    }
  })
  const res_1 = await res.json()
  return res_1
}


export const usePosts = (key, query) => {
  return useQuery([key, query], async () => {
    const url = `${"https://api.yehey.jp/query/aggregate"}?db=${query.db}&col=${query.col
      }&key=${getKey(JSON.stringify(query.agg).length)}`
    const res = await fetch(url, {
      method: "POST",
      body: JSON.stringify(query.agg),
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      }
    })
    return res.json()
  })
}

export const cformat = (value) => {
  return currency(value, { separator: ',', symbol: '', precision: 0 }).format()
}

export function difference(object, base) {
  return _.transform(object, (result, value, key) => {
    if (!_.isEqual(value, base[key])) {
      result[key] = _.isObject(value) && _.isObject(base[key]) ? _.difference(value, base[key]) : value
    }
  })
}


export class order {
  constructor(order) {
    this.order = order
    this.getTotal = this.handleGetTotal.bind(this)
    this.getInitial = this.handleGetInitial.bind(this)
    this.getBalance = this.handleGetBalance.bind(this)
  }

  handleGetTotal() {
    return ((_.get(data, "jumboBoxPrice", 0) + _.get(data, "jumboBoxAddPrice", 0)) * _.get(data, "jumboBoxCount", 0))
      +
      ((_.get(data, "twinBoxPrice", 0) + _.get(data, "twinBoxAddPrice", 0)) * _.get(data, "twinBoxCount", 0))
      +
      ((_.get(data, "halfBoxPrice", 0) + _.get(data, "halfBoxAddPrice", 0)) * _.get(data, "halfBoxCount", 0))
  }

  handleGetInitial() {
    if (this.order.paymentType === "depPayment") {
      return (_.get(this.order, "jumboBoxInitPrice", 0) * _.get(this.order, "jumboBoxCount", 0))
        +
        (_.get(this.order, "twinBoxInitPrice", 0) * _.get(this.order, "twinBoxCount", 0))
        +
        (_.get(this.order, "halfBoxInitPrice", 0) * _.get(this.order, "halfBoxCount", 0))
    } else {
      return 0
    }
  }

  handleGetBalance() {
    return this.handleGetTotal() - this.handleGetInitial()
  }
}

/**
 * CValidateEmail
 * @param {string} mail
 */
export const validateEmail = (mail) => {
  if (mail.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) return true
  else return false
}

export const dataURLtoFile = (dataUrl, filename) => {
  const arr = dataUrl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n) {
    u8arr[n - 1] = bstr.charCodeAt(n - 1)
    n -= 1 // to make eslint happy
  }
  return new File([u8arr], filename, { type: mime })
}