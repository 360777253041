// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'
import { initialAbility } from '../../../configs/acl/initialAbility'
import { updateAbility } from '../../../utility/Utils'

const config = useJwt.jwtConfig

// ** Handle User Login
export const handleLogin = data => {
  return dispatch => {
    dispatch({
      type: 'LOGIN',
      data,
      config
    })
    // Disabled Session token
    //      [config.storageTokenKeyName]: data[config.storageTokenKeyName],
    //[config.storageRefreshTokenKeyName]: data[config.storageRefreshTokenKeyName]
    // ** Add to user, accessToken & refreshToken to localStorage
    localStorage.setItem('userData', JSON.stringify(data))
    //localStorage.setItem(config.storageTokenKeyName, JSON.stringify(data.accessToken))
    //localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(data.refreshToken))
  }
}

// ** Handle User Logout
export const handleLogout = (ability) => {
  return dispatch => {
    updateAbility(ability, { ability: initialAbility })
    dispatch({ type: 'LOGOUT', [config.storageTokenKeyName]: null, [config.storageRefreshTokenKeyName]: null })

    // ** Remove user, accessToken & refreshToken from localStorage
    localStorage.removeItem('userData')
    //localStorage.removeItem(config.storageTokenKeyName)
    //localStorage.removeItem(config.storageRefreshTokenKeyName)
  }
}
